import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import { Link } from 'gatsby'
import ProctorDesignUsecaseImg from '../images/transformation-usecase/protor-design-usecase.png'
import { FaAngleLeft } from "react-icons/fa"

const ProctorDesignUsecase = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="px-5 pt-4">
                <Link to="/transformation-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Transformation</p></Link>
            </div>

            <div className="transformation-usecase pb-5 pt-4">
                <div className="bg-proctor"></div>
            </div>


            <div className="container">
                <h1 className="font-weight-bold text-center mt-md-5 big-text">Go-to-market strategy to disrupt the market for a client</h1>

                <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Design thinking</span>Agro manufacturing</p>

                <div style={{ borderBottom: '5px solid #E41700' }}></div>
            </div>

            <div className="py-5 my-5"></div>

            <div className="bg-client-usecase">
                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 my-5">
                            <p className="font-weight-bold" style={{fontSize: '1.5rem'}}>The Client</p>

                            <p className="text-secondary" style={{fontSize: '1.15rem', lineHeight: '30px'}}>Our client was a top manufacturing company with a sustainable plan to produce dehydrated fruits that can be rehydrated when dipped in water. They needed an iterative go-to-market strategy to disrupt the market, and boost sales and revenue.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-solution-usecase">
                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 mx-auto my-5">
                            <p className="font-weight-bold" style={{fontSize: '1.5rem'}}>Our Solution</p>

                            <p className="text-secondary" style={{fontSize: '1.15rem', lineHeight: '30px'}}>We implemented a business model canvas to document their existing business models, and were able to come up with a more optimized model using the power of design thinking. The new model transformed the product’s value proposition and infrastructure, defined the customer base and market segments, and forecasted finances all of which paid off for a successful market breakthrough. We also aided in the design of website prototypes to boost their digital footprint.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row pt-5 mt-5">
                    <div className="col-md-6 mx-auto">
                    <img src={ProctorDesignUsecaseImg} alt="Journey Map" className="img-fluid" />
                        <p className="text-secondary text-center mt-1">Training the staff on must-knows of organisational success</p>
                    </div>
                </div>
            </div>


            <div className="my-5 py-5"></div>

            <div className="bg-impact-usecase">
                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 my-5">
                            <p className="font-weight-bold" style={{fontSize: '1.5rem'}}>The Impact</p>

                            <ul>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Fostered a culture of agile problem-solving.</p></li>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Defined unique and timeless business propositions.</p></li>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Ensured effective market disruption.</p></li>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Boosted sales, customer acquisition and revenue.</p></li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row py-5 my-5">
                    <div className="col-md-5 mx-auto mb-5 text-center">
                    <Link to={`/contact`} state={{ path: 'transformation' }}><button style={{height: '48px'}} class="btn btn-red h-100 btn-red-black px-3 no-border-radius">Get started</button></Link>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default ProctorDesignUsecase
